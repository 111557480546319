<template>
  <div>
    
    <div class="row justify-content-center mt-5">
      <div class="col-xl-7 col-lg-8 col-md-10">
        <div class="section-tittle mb-60 text-center" :class="{ 'wow fadeInUp': animate }" data-wow-duration="2s"
          data-wow-delay=".2s" :style="animationStyle">
          <h2>{{$t('NEW')}}<br />{{$t('ARRIVAL')}}</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <p><span class="h4">{{$t('At LYNX,')}}</span> {{ $t('about_home_p2') }}</p>
    </div>
    <section class="product spad mt-5">
      <div class="container">
        <div class="row product__filter">
          <div v-if="loading" class="text-center">{{[$t('Loading')]}}...</div>
          <div v-else-if="error" class="text-center text-danger">{{ error }}</div>
          <div v-else v-for="(product, index) in products" :key="index"
            class="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div :class="['product__item', product.name ? product.name.toLowerCase() : '']">
              <a :href="'/product/' + product.id">
                <div class="product__item__pic set-bg"
                  :style="{ backgroundImage: `url(${storageUrl}/${product.picture})` }">
                  <span class="label">{{$t('New')}}</span>
                  <ul class="product__hover">
                    <li><a href="#" @click.prevent="addToWhishlist(product)"><img src="@/assets/img/icon/heart.png"
                          :title="$t('Whishlist')"></a></li>
                  </ul>
                </div>
              </a>
              <div class="product__item__text">
                <h6>{{ product.name }}</h6>
                <!--<div v-for="option in product.options" :key="option.id">
                  <label>{{ option.name }}</label> {{ product.id }} - {{ option.id }}
                  <select v-model="selectedValues[option.id]">
                    <option v-for="valeur in option.optionvaleurs" :key="valeur.id" :value="valeur.id">
                      {{ valeur.name }}
                    </option>
                  </select>
                </div>-->
                <h5>
                  <span v-if="product.prix_old" class="prix-old">{{ product.prix_old }} DH</span>
                  <span class="text-danger">{{ product.prix }} DH </span>
                </h5>
                <input type="number" v-model.number="productQuantities[product.id]" min="1" class="form-control mb-2"
                  placeholder="Quantity" hidden disabled />
                <a href="#" class="add-cart" @click.prevent="addToCart(product)" v-if="product.totalQty > 0">
                  + {{$t('Add To Cart')}}
                </a>
                <a href="#" class="add-cart" v-else>{{$t('Out of stock')}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center"><a href="/shop" class="primary-btn">{{$t('Shop now')}} <i
            class="fa-solid fa-arrow-right"></i></a></div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import { API_URL, STORAGE_URL } from '@/services/apis';
import Swal from 'sweetalert2';
export default {
  name: 'NewArrivalComponent',
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  },
  data() {
    return {
      products: [],
      productQuantities: {},
      selectedOptions: {},
      loading: true,
      error: null,
      animate: true,
      newAr: 'hot-sales',
      selectedValues: {}
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(API_URL + '/produits/newarrival');
        this.products = response.data.data;
        this.products.forEach(product => {
          this.productQuantities = {
            ...this.productQuantities,
            [product.id]: 1 // Set default quantity to 1
          };
          this.selectedOptions[product.id] = {};
          product.options.forEach(option => {
            this.selectedOptions[product.id][option.id] = {
              optionId: option.id,
              optionValeurId: null
            };
          });
        });
        //console.log(response.data.data)
      } catch (err) {
        this.error = 'Failed to load products';
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    addToCart(product) {
      const quantity = this.productQuantities[product.id];
      const selectedData = [];

      product.options.forEach(option => {
        const optionId = option.id;
        const valeurId = this.selectedValues[optionId];
        if (valeurId) {
          selectedData.push({ productId: product.id, optionId, valeurId });
        }
      });

      this.$store.dispatch('addToCart', { product, quantity, selectedData });
      Swal.fire({
        toast: true,
        position: 'top-end', // Top-right corner
        icon: 'success',
        title: `${product.name} has been added to your cart!`,
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
    },
    addToWhishlist(product) {
      console.log('whishlist')
      this.$store.dispatch('addToWhishlist', product);
    }
  },
  computed: {
    storageUrl() {
      return STORAGE_URL;
    },
    animationStyle() {
      return {
        visibility: 'visible',
        'animation-duration': '2s',
        'animation-delay': '0.2s',
        'animation-name': 'fadeInUp',
      };
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.cart-dropdown {
  position: absolute;
  right: 15px;
  /* Adjust to align with the cart icon */
  top: 60px;
  /* Adjust to position below the header */
  width: 300px;
  /* Adjust width as needed */
  z-index: 1000;
}

.cart-fade-enter-active,
.cart-fade-leave-active {
  transition: opacity 0.5s;
}

.cart-fade-enter,
.cart-fade-leave-to {
  opacity: 0;
}

.product__item__pic {
  border: 1px solid rgb(232, 225, 225);
}

.product__item__text h5 .prix-old {
  text-decoration: line-through;
  margin-right: 10px;
  opacity: 45%;
  color: #999;
  /* Optional: to differentiate the old price color */
}

.form-group {
  margin-bottom: 1rem;
}

.custom-select {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.custom-select option {
  color: black;
}

.text-black {
  color: black;
}

:root {
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-hover: #192a56;
  --bs-primary: #565c64;
}

.product-grid {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.product-grid .product-image {
  overflow: hidden;
  position: relative;
}

.product-grid .product-image a.image {
  display: block;
}

.product-grid .product-image img {
  width: 100%;
  height: auto;
}

.product-grid .product-image .pic-1 {
  transition: all 0.3s ease 0s;
}

.product-grid .product-image:hover .pic-1 {
  transform: translateX(100%);
}

.product-grid .product-image .pic-2 {
  width: 100%;
  height: 100%;
  transform: translateX(-101%);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}

.product-grid .product-image:hover .pic-2 {
  transform: translateX(0);
}

.product-grid .product-sale-label {
  color: #fff;
  background: var(--bs-hover);
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 2px 8px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.product-grid .product-like-icon {
  color: #696969;
  font-size: 22px;
  line-height: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.product-grid .product-like-icon:hover {
  color: var(--bs-hover);
}

.product-grid .product-like-icon:before,
.product-grid .product-like-icon:after {
  content: attr(data-tip);
  color: #fff;
  background-color: #000;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 7px 5px;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 15px;
  transition: all 0.3s ease 0s;
}

.product-grid .product-like-icon:after {
  content: "";
  height: 15px;
  width: 15px;
  padding: 0;
  transform: translateX(-50%) rotate(45deg);
  right: auto;
  left: 50%;
  top: 15px;
  z-index: -1;
}

.product-grid .product-like-icon:hover:before,
.product-grid .product-like-icon:hover:after {
  visibility: visible;
  top: 30px;
}

.product-grid .product-links {
  width: 170px;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  bottom: -50px;
  left: 50%;
  transition: all 0.3s ease 0s;
}

.product-grid:hover .product-links {
  bottom: 40px;
  opacity: 1;
}

.product-grid .product-links li {
  display: inline-block;
  margin: 0 2px;
}

.product-grid .product-links li a {
  color: #fff;
  background: #192a56;
  font-size: 16px;
  line-height: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  transition: all 0.3s ease 0s;
}

.product-grid:hover .product-links li a:hover {
  background: #333;
}

.product-grid .product-content {
  text-align: left;
  padding: 15px 0 0;
}

.product-grid .title {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 8px;
}

.product-grid .title a {
  color: #333;
  transition: all 0.3s ease 0s;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}

.product-grid .title a:hover {
  color: var(--bs-hover);
}

.product-grid .price {
  color: var(--bs-hover);
  font-size: 16px;
  font-weight: 500;
}

.product-grid .price span {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  margin: 0 5px 0 0;
}

@media screen and (max-width: 990px) {
  .product-grid {
    margin: 0 0 30px;
  }
}
</style>
