<template>
    <section class="d-flex align-items-center justify-content-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <!-- card left -->
                    <div class="product-imgs">
                        <div class="img-display">
                            <div class="img-showcase">
                                <img :src="storageUrl + '/' + currentImage" alt="product image">
                            </div>
                        </div>
                        <div class="img-select d-flex">
                            <!-- Iterate over imageproduits to show thumbnail images -->
                            <div v-for="(image, index) in products.imageproduits" :key="'thumbnail-' + index"
                                class="img-item me-2">
                                <a href="#" :data-id="'showcase-' + index" @click.prevent="changeImage(image.path)">
                                    <img :src="storageUrl + '/' + image.path" alt="product image">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- card right -->
                <div class="col-md-6">
                    <div class="product-content">
                        <h2 class="product-title">{{ products.name }}</h2>

                        <div class="product-price">
                            <p class="last-price">Old Price: <span>{{ products.prix_old }} DH</span></p>
                            <p class="new-price">New Price: <span>{{ products.prix }} DH</span></p>
                        </div>

                        <div class="product-detail">
                            <h2>about this item: </h2>
                            <p v-html="products.short_description">
                            </p>
                            <ul>
                                <li>
                                    <i class="fa fa-check text-success"></i>
                                    <a style="text-decoration: none;cursor:pointer;" class="text-primary" @click="openModal">
                                        Size
                                    </a>
                                </li>
                                <li><i class="fa fa-check text-success"></i> Categories:
                                    <span>
                                        <b class="text-dark" v-for="categorie in products.categories"
                                            :key="categorie.id">
                                            <a :href="'/product/category/' + categorie.slug" class="cat-link">{{
                                                categorie.name }}</a>,
                                        </b>
                                    </span>
                                </li>
                                
                                <li v-if="products.totalQty > 0"><i class="fa fa-check text-success"></i> Stock:
                                    <span><b class="text-dark">{{
                                        products.totalQty }} PCS</b></span>
                                </li>
                                <li v-else-if="products.totalQty == 0" class="text-danger"><i
                                        class="fa fa-check text-success"></i> OUT OF
                                    STOCK</li>
                                <li><i class="fa fa-check text-success"></i> SKU: <span>{{ products.ugs }}</span></li>
                                <li><i class="fa fa-check text-success"></i> Shipping Area: <span>All over Morocco
                                    </span></li>
                                <li><i class="fa fa-check text-success"></i> Shipping Fee: <span>Free</span></li>
                            </ul>
                            
                                    <div v-if="isModalOpen" class="modal-size-overlay" @click="closeModal">
                                        <div class="modal-size-content" @click.stop>
                                            <span class="modal-size-close" @click="closeModal">&times;</span>
                                            <div class="modal-size-body">
                                                <h2 class="mt-5">Guide des tailles</h2>
                                                <hr />
                                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="site-btn active" id="pills-home-tab"
                                                            data-bs-toggle="pill" data-bs-target="#pills-home"
                                                            type="button" role="tab" aria-controls="pills-home"
                                                            aria-selected="true">L 51-20</button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="site-btn" type="button" id="pills-profile-tab"
                                                            data-bs-toggle="pill" data-bs-target="#pills-profile"
                                                            role="tab" aria-controls="pills-profile"
                                                            aria-selected="false">XL 53-20</button>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade show active" id="pills-home"
                                                        role="tabpanel" aria-labelledby="pills-home-tab">
                                                        <p class="text-success">Cette taille convient à la plupart des
                                                            gens
                                                        </p>
                                                        <b>Charnière à charnière <span>136</span></b><br/>
                                                        <b class="mt-3">QU'EST-CE QUE LA TAILLE DE LA MONTURE ?</b>
                                                        <p>La taille de la monture correspond à la largeur horizontale
                                                            de la monture, mesurée entre le centre de la charnière
                                                            gauche et celui de la charnière droite. Nos montures
                                                            existent en une ou plusieurs tailles. Voir la gamme dans le
                                                            tableau ci-dessous.</p>
                                                        <b>COMMENT PUIS-JE SAVOIR QUELLE EST LA BONNE TAILLE POUR MOI
                                                            ?</b>
                                                        <p>Pour trouver la taille de monture qui vous convient, utilisez
                                                            une règle pour mesurer la distance entre les charnières
                                                            d'une paire de lunettes que vous possédez déjà et qui vous
                                                            va bien. Tenez compte de la marge de tolérance de +/- 4 mm.
                                                        </p>
                                                        <img
                                                            src="https://media.ray-ban.com/size-guide/assets/fr-FR/FrameSize_fr.png" />
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Size</th>
                                                                    <th scope="col">mm</th>
                                                                    <th scope="col">inches</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>XXS</td>
                                                                    <td>&lt;121</td>
                                                                    <td>&lt;4.8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XS</td>
                                                                    <td>121-124</td>
                                                                    <td>4.8-4.9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>S</td>
                                                                    <td>125-128</td>
                                                                    <td>4.9-5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>M</td>
                                                                    <td>129-132</td>
                                                                    <td>5.1-5.2</td>
                                                                </tr>
                                                                <tr class="table-active">
                                                                    <td>L</td>
                                                                    <td>133-136</td>
                                                                    <td>5.3-5.4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XL</td>
                                                                    <td>137-140</td>
                                                                    <td>5.4-5.5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XXL</td>
                                                                    <td>&gt;140</td>
                                                                    <td>&gt;5.5</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <b>Autres mesures</b>
                                                        <p>Si vous portez déjà des lunettes vous remarquerez des numéros gravés à l’intérieur de la branche de la monture :
                                                        </p>
                                                        <img src="https://media.ray-ban.com/size-guide/assets/fr-FR/Measurements_fr.png" />
                                                    </div>
                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                                        aria-labelledby="pills-profile-tab">
                                                        <b>Charnière à charnière <span>140</span></b><br/>
                                                        <b class="mt-3">QU'EST-CE QUE LA TAILLE DE LA MONTURE ?</b>
                                                        <p>La taille de la monture correspond à la largeur horizontale de la monture, mesurée entre le centre de la charnière gauche et celui de la charnière droite. Nos montures existent en une ou plusieurs tailles. Voir la gamme dans le tableau ci-dessous.
                                                        </p>
                                                        <b>COMMENT PUIS-JE SAVOIR QUELLE EST LA BONNE TAILLE POUR MOI ?
                                                        </b>
                                                        <p>Pour trouver la taille de monture qui vous convient, utilisez une règle pour mesurer la distance entre les charnières d'une paire de lunettes que vous possédez déjà et qui vous va bien. Tenez compte de la marge de tolérance de +/- 4 mm.
                                                        </p>
                                                        <img src="https://media.ray-ban.com/size-guide/assets/fr-FR/FrameSize_fr.png" />
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Size</th>
                                                                    <th scope="col">mm</th>
                                                                    <th scope="col">inches</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>XXS</td>
                                                                    <td>&lt;121</td>
                                                                    <td>&lt;4.8</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XS</td>
                                                                    <td>121-124</td>
                                                                    <td>4.8-4.9</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>S</td>
                                                                    <td>125-128</td>
                                                                    <td>4.9-5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>M</td>
                                                                    <td>129-132</td>
                                                                    <td>5.1-5.2</td>
                                                                </tr>
                                                                <tr class="table-active">
                                                                    <td>L</td>
                                                                    <td>133-136</td>
                                                                    <td>5.3-5.4</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XL</td>
                                                                    <td>137-140</td>
                                                                    <td>5.4-5.5</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XXL</td>
                                                                    <td>&gt;140</td>
                                                                    <td>&gt;5.5</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <b>Autres mesures</b>
                                                        <p>Si vous portez déjà des lunettes vous remarquerez des numéros gravés à l’intérieur de la branche de la monture :</p>
                                                        <img src="https://media.ray-ban.com/size-guide/assets/fr-FR/Measurements_fr.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div>

                        <div class="purchase-info" v-if="products.totalQty > 0">
                            <input type="number" :min="1" :max="products.totalQty" v-model="quantityValue"
                                class="form-control d-inline-block w-auto">
                            <button type="button" class="btn btn-primary" @click="addToCart(products)">
                                Add to Cart <i class="fa fa-shopping-cart"></i>
                            </button>
                            <button type="button" class="btn btn-secondary" @click="addToWhishlist(products)"><i
                                    class="fa fa-heart"></i> add to
                                wishlist</button>
                        </div>
                        <div class="social-links">

                            <p>Share : </p>
                            <a data-mdb-ripple-init class="btn btn-primary" style="background-color: #3b5998;" href="#!"
                                role="button"><i class="fa fa-facebook-f"></i></a>

                            <!-- Twitter -->
                            <a data-mdb-ripple-init class="btn btn-primary" style="background-color: #55acee;" href="#!"
                                role="button"><i class="fa fa-twitter"></i></a>
                            <a data-mdb-ripple-init class="btn btn-primary" style="background-color: #ac2bac;" href="#!"
                                role="button"><i class="fa fa-instagram"></i></a>
                            <a data-mdb-ripple-init class="btn btn-primary" style="background-color: #25d366;" href="#!"
                                role="button"><i class="fa fa-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="row">
        <div class="col-lg-12">
            <div class="product__details__tab">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tabs-5" role="tab">Description</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="tabs-5" role="tabpanel">
                        <div class="product__details__tab__content">
                            <p class="note">{{ products.name }}</p>
                            <div class="product__details__tab__content__item">
                                <h5>Products Infomation</h5>
                                <p v-html="products.description"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <CertifComponent />
    <hr />
    <h3 class="text-center mt-5">Related Products</h3>
    <section class="d-flex align-items-center justify-content-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div v-for="(product, index) in relatedProducts" :key="index"
                            :class="['col-lg-3 col-md-6 col-sm-6 mix']">
                            <div :class="['product__item', product.name ? product.name.toLowerCase() : '']">
                                <a :href="'/product/' + product.id">
                                    <div class="product__item__pic set-bg"
                                        :style="{ backgroundImage: `url(${storageUrl}/${product.picture})` }">
                                        <span class="label">{{ $t('Related') }}</span>
                                        <ul class="product__hover">
                                            <li><a href="#" @click.prevent="addToWhishlist(product)"><img
                                                        src="@/assets/img/icon/heart.png" alt=""></a></li>
                                        </ul>
                                    </div>
                                </a>
                                <div class="product__item__text">
                                    <h6>{{ product.name }}</h6>

                                    <h5>
                                        <span v-if="product.prix_old" class="prix-old">{{ product.prix_old
                                            }} DH</span>
                                        <span class="text-danger">{{ product.prix }} DH</span>
                                    </h5>
                                    <input type="number" v-model.number="productQuantities[product.id]" min="1"
                                        class="form-control mb-2" placeholder="Quantity" hidden disabled />
                                    <a href="#" class="add-cart" @click.prevent="addToCart(product)"
                                        v-if="product.totalQty > 0">+ {{ $t('Add To Cart') }}</a>
                                    <a href="#" class="add-cart" v-else>{{ $t('Out of stock') }}</a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="product__pagination">
                                <a v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }"
                                    href="#" @click.prevent="changePage(page)">{{ page }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL, STORAGE_URL } from '@/services/apis';
import { useHead } from '@vueuse/head';
import CertifComponent from '../home/CertifComponent.vue';

export default {
    name: 'ProductPage',
    components: {
        CertifComponent
    },
    props: {
        productId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            products: [],
            categories: [],
            tags: [],
            productQuantities: {},
            relatedProducts: [],
            loading: true,
            error: null,
            animate: true,
            newAr: 'hot-sales',
            selectedCategory: null,
            selectedTags: [],
            sortCriteria: 'lowToHigh',
            currentPage: 1,
            totalPages: 1,
            searchQuery: '',
            quantityValue: 1,
            currentImage: '',
            isModalOpen: false,
        };
    },

    methods: {
        async fetchProducts() {
            try {
                const response = await axios.get(API_URL + `/products/${this.productId}`);
                //console.log('response')
                //console.log(response.data)
                this.products = response.data.data.product;
                this.relatedProducts = response.data.data.relatedProducts;
                this.currentImage = this.products.imageproduits[0]?.path || '';
                this.loading = false;
            } catch (err) {
                this.error = 'Failed to load products';
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        changeImage(imagePath) {
            this.currentImage = imagePath;
        },
        addToCart(product) {
            const quantity = this.quantityValue || 1;
            if (quantity > product.totalQty) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'The quantity you selected exceeds the available stock!',
                });
            } else {
                this.$store.dispatch('addToCart', { product, quantity });
                Swal.fire({
                    toast: true,
                    position: 'top-end', // Top-right corner
                    icon: 'success',
                    title: `${product.name} has been added to your cart!`,
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                });
            }
        },
        addToWhishlist(product) {
            //console.log('whishlist')
            this.$store.dispatch('addToWhishlist', product);
        },
        openModal() {
            this.isModalOpen = true;
            document.body.style.overflow = 'hidden';
        },
        closeModal() {
            this.isModalOpen = false;
            document.body.style.overflow = 'auto';
            this.fetchProducts();
        },
    },
    computed: {
        storageUrl() {
            return STORAGE_URL;
        },
        animationStyle() {
            return {
                visibility: 'visible',
                'animation-duration': '2s',
                'animation-delay': '0.2s',
                'animation-name': 'fadeInUp',
            };
        },
    },
    created() {
        this.fetchProducts().then(() => {
            this.$nextTick(() => {
                useHead({
                    title: this.products ? `LYNX | Acheter Lunettes de Soleil au Maroc - Votre Boutique en Ligne | ${this.products.name} ` : 'LYNX | Acheter Lunettes de Soleil au Maroc - Votre Boutique en Ligne',
                    meta: [
                        { name: 'description', content: 'LYNX Découvrez notre collection de lunettes de soleil tendance au Maroc. Protection UV, matériaux de qualité, et livraison rapide. Achetez maintenant!' },
                        { name: 'keywords', content: 'LYNX,lynx,look different,acheter lunettes de soleil Maroc, lunettes de soleil tendance, protection UV, lunettes en bois, lunettes de vue Maroc' }
                    ]
                });
            });
        }).catch(error => {
            console.error('Failed to fetch products:', error);
            // Handle error if needed
        });
    },

};
</script>
<style scoped>
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

body {
    line-height: 1.5;
}

.card-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

img {
    width: 100%;
    display: block;
}

.img-display {
    overflow: hidden;
}

.img-showcase {
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}

.img-showcase img {
    min-width: 100%;
}

.img-select {
    display: flex;
}

.img-item {
    margin: 0.3rem;
}

.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
    margin-right: 0;
}

.img-item:hover {
    opacity: 0.8;
}

.product-content {
    padding: 2rem 1rem;
}

.product-title {
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #12263a;
    margin: 1rem 0;
}

.product-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #12263a;
}

.product-link {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: #256eff;
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}

.product-link:hover {
    opacity: 0.9;
}

.product-rating {
    color: #ffc107;
}

.product-rating span {
    font-weight: 600;
    color: #252525;
}

.product-price {
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}

.product-price span {
    font-weight: 400;
}

.last-price span {
    color: #f64749;
    text-decoration: line-through;
}

.new-price span {
    color: #256eff;
}

.product-detail h2 {
    text-transform: capitalize;
    color: #12263a;
    padding-bottom: 0.6rem;
}

.product-detail p {
    font-size: 0.9rem;
    padding: 0.3rem;
    opacity: 0.8;
}

.product-detail ul {
    margin: 1rem 0;
    font-size: 0.9rem;
}

.product-detail ul li {
    margin: 0;
    list-style: none;
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    opacity: 0.9;
}

.product-detail ul li span {
    font-weight: 400;
}

.purchase-info {
    margin: 1.5rem 0;
}

.purchase-info input,
.purchase-info .btn {
    border: 1.5px solid #ddd;
    border-radius: 25px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}

.purchase-info input {
    width: 60px;
}

.purchase-info .btn {
    cursor: pointer;
    color: #fff;
}

.purchase-info .btn:first-of-type {
    background: #256eff;
}

.purchase-info .btn:last-of-type {
    background: #f64749;
}

.purchase-info .btn:hover {
    opacity: 0.9;
}

.social-links {
    display: flex;
    align-items: center;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #000;
    border: 1px solid #000;
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 0.8rem;
    transition: all 0.5s ease;
}

.social-links a:hover {
    background: #000;
    border-color: transparent;
    color: #fff;
}

@media screen and (min-width: 992px) {
    .card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }

    .card-wrapper {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-imgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .product-content {
        padding-top: 0;
    }
}

/* Full page overlay */
.modal-size-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    overflow-y: hidden;
    /* Prevent scrolling of background content */
}

/* Modal content */
.modal-size-content {
    width: 501px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;
    z-index: 1001;
    overflow-y: auto;
    /* Make modal content scrollable */
}

/* Close button */
.modal-size-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

/* Modal body styling */
.modal-size-body {
    margin-top: 20px;
}

.modal-size-body h2 {
    margin-bottom: 15px;
}

.modal-size-body img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}


.product__item__text h5 .prix-old {
  text-decoration: line-through;
  margin-right: 10px;
  opacity: 45%;
  color: #999;
}
.cat-link{
    color:blue;
    text-decoration:none;
    cursor:pointer;
}
.cat-link a:hover{
    color:black
}
</style>