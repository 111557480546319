<template>
    <section class="shop spad">
        <div class="container">
            <div class="row">
                <!-- Inside the Shop.vue template -->
                <div class="col-lg-3">
                    <div class="shop__sidebar">
                        <div class="shop__sidebar__search">
                            <form @submit.prevent>
                                <input type="text" placeholder="Search..." v-model="searchQuery"
                                    @input="filterProducts" />
                                <button type="submit"><span class="icon_search"></span></button>
                            </form>

                        </div>
                        <div class="shop__sidebar__accordion">
                            <!-- Categories accordion -->
                            <div class="accordion" id="accordionExample">
                               
                                <div class="card">
                                    <div id="headingOne">
                                        <h2 class="mb-0">
                                            <span class="card-heading" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                {{ $t('Latest Products') }}
                                            </span>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                                        <div class="card-body ">
                                            <div class="shop__sidebar__categories">
                                                <div v-for="(product, index) in lastProduits" :key="index"
                                                    :class="['col-lg-12 col-md-6 col-sm-6 mix']">
                                                    <div
                                                        :class="['product__item', product.name ? product.name.toLowerCase() : '']">
                                                        <a :href="'/product/' + product.id">
                                                            <div class="product__item__pic set-bg"
                                                                :style="{ backgroundImage: `url(${storageUrl}/${product.picture})` }">
                                                                <span class="label">{{ $t('New') }}</span>
                                                                <ul class="product__hover">
                                                                    <li><a href="#"
                                                                            @click.prevent="addToWhishlist(product)"><img
                                                                                src="@/assets/img/icon/heart.png"
                                                                                alt=""></a></li>
                                                                </ul>
                                                            </div>
                                                        </a>
                                                        <div class="product__item__text">
                                                            <h6>{{ product.name }}</h6>

                                                            <h5>
                                                                <span v-if="product.prix_old" class="prix-old">{{
                                    product.prix_old }} DH</span>
                                                                <span class="text-danger">{{ product.prix }} DH</span>
                                                            </h5>
                                                            <a :href="'/product/' + product.id" class="add-cart"
                                                           >+ {{ $t('Add To Cart')}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9">
                    <div class="shop__product__option">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="shop__product__option__left">
                                    <p>{{ $t('Showing') }} 1–12 {{ $t('of') }} {{ filteredProducts.length }}
                                        {{ $t('results') }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="shop__product__option__right">
                                    <p>{{ $t('Sort by Price') }}:</p>
                                    <select @change="sortProducts" v-model="sortCriteria">
                                        <option value="lowToHigh">{{ $t('Low To High') }}</option>
                                        <option value="highToLow">{{ $t('High To Low') }}</option>
                                        <option value="0-300">0 DH - 300 DH</option>
                                        <option value="300+">300 DH +</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div v-for="(product, index) in filteredProducts" :key="index"
                            :class="['col-lg-3 col-md-6 col-sm-6 mix']">
                            <div :class="['product__item', product.name ? product.name.toLowerCase() : '']">
                                <a :href="'/product/' + product.id">
                                    <div class="product__item__pic set-bg"
                                        :style="{ backgroundImage: `url(${storageUrl}/${product.picture})` }">
                                        
                                        <ul class="product__hover">
                                            <li><a href="#" @click.prevent="addToWhishlist(product)"><img
                                                        src="@/assets/img/icon/heart.png" alt=""></a></li>
                                        </ul>
                                    </div>
                                </a>
                                <div class="product__item__text">
                                    <h6>{{ product.name }}</h6>

                                    <h5>
                                        <span v-if="product.prix_old" class="prix-old">{{ product.prix_old }} DH</span>
                                        <span class="text-danger">{{ product.prix }} DH</span>
                                    </h5>
                                    <input type="number" v-model.number="productQuantities[product.id]" min="1"
                                        class="form-control mb-2" placeholder="Quantity" hidden disabled />
                                    <a href="#" class="add-cart" @click.prevent="addToCart(product)"
                                        v-if="product.totalQty > 0">+ {{ $t('Add To Cart')}}</a>
                                    <a href="#" class="add-cart" v-else>{{$t('Out of stock')}}</a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="product__pagination">
                                <a v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }"
                                    href="#" @click.prevent="changePage(page)">{{ page }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { API_URL,STORAGE_URL } from '@/services/apis';

export default {
    name: 'CategoriePage',
    props: {
        slug: {
            type: String,
            required: true
        }
    },
    setup() {
        const { t } = useI18n();

        return {
            t
        };
    },
    data() {
        return {
            products: [],
            categories: [],
            tags: [],
            lastProduits: [],
            productQuantities: {},
            loading: true,
            error: null,
            animate: true,
            newAr: 'hot-sales',
            selectedCategory: null,
            selectedTags: [],
            sortCriteria: 'lowToHigh',
            currentPage: 1,
            totalPages: 1,
            searchQuery: '',
        };
    },
    methods: {
        async fetchProducts(page = 1) {
            try {
                const response = await axios.get(API_URL + `/products/category/${this.slug}/index?page=${page}`);
                //console.log('response')
                //console.log(response)
                this.products = response.data.data.products.data;
                this.products.forEach(product => {
                    this.productQuantities = {
                        ...this.productQuantities,
                        [product.id]: 1 // Set default quantity to 1
                    };
                });
                this.tags = response.data.data.tags;
                this.lastProduits = response.data.data.lastProduits
                this.currentPage = response.data.data.products.current_page;
                this.totalPages = response.data.data.products.last_page;
            } catch (err) {
                this.error = 'Failed to load products';
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
        changePage(page) {
            if (page !== this.currentPage && page > 0 && page <= this.totalPages) {
                this.currentPage = page;
                this.fetchProducts(page);
            }
        },
        filterByTag(tag) {
            const index = this.selectedTags.indexOf(tag);
            if (index > -1) {
                this.selectedTags.splice(index, 1); // Remove tag if already selected
            } else {
                this.selectedTags.push(tag); // Add tag if not already selected
            }
        },
        clearTagFilter() {
            this.selectedTags = []; // Clear selected tags
        },
        sortProducts() {
            //console.log('iside')
            switch (this.sortCriteria) {
                case 'lowToHigh':
                    this.filteredProducts = this.filteredProducts.sort((a, b) => a.prix - b.prix);
                    break;
                case 'highToLow':
                    this.filteredProducts = this.filteredProducts.sort((a, b) => b.prix - a.prix);
                    break;
                case '0-300':
                    this.filteredProducts = this.products.filter(product => product.prix >= 0 && product.prix <= 300);
                    break;
                case '300+':
                    //console.log('300+');
                    this.filteredProducts = this.products.filter(product => product.prix > 300);
                    break;
                default:
                    break;
            }
        },
        addToCart(product) {
            const quantity = this.productQuantities[product.id] || 1;
            if (quantity > product.totalQty) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'The quantity you selected exceeds the available stock!',
                });
            } else {
                this.$store.dispatch('addToCart', { product, quantity });
                Swal.fire({
                    toast: true,
                    position: 'top-end', // Top-right corner
                    icon: 'success',
                    title: `${product.name} has been added to your cart!`,
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                });
            }
        },
        addToWhishlist(product) {
            //console.log('whishlist')
            this.$store.dispatch('addToWhishlist', product);
        }
    },
    computed: {
        storageUrl() {
            return STORAGE_URL;
        },
        filteredProducts() {
            let filtered = this.products;
            if (this.selectedTags.length > 0) {
                filtered = filtered.filter(product =>
                    this.selectedTags.every(tag =>
                        product.tags.some(productTag => productTag.id === tag.id)
                    )
                );
            }
            if (this.searchQuery) {
                const query = this.searchQuery.toLowerCase();
                filtered = filtered.filter(product =>
                    product.name.toLowerCase().includes(query)
                );
            }
            switch (this.sortCriteria) {
                case 'lowToHigh':
                    filtered = filtered.sort((a, b) => a.prix - b.prix);
                    break;
                case 'highToLow':
                    filtered = filtered.sort((a, b) => b.prix - a.prix);
                    break;
                case '0-300':
                    filtered = filtered.filter(product => product.prix >= 0 && product.prix <= 300);
                    break;
                case '300+':
                    filtered = filtered.filter(product => product.prix > 300);
                    break;
                default:
                    break;
            }
            return filtered;
        },
        animationStyle() {
            return {
                visibility: 'visible',
                'animation-duration': '2s',
                'animation-delay': '0.2s',
                'animation-name': 'fadeInUp',
            };
        },
    },
    created() {
        this.fetchProducts();
    },
};
</script>

<style scoped>
.container {
    margin-top: 50px;
}

.cart-dropdown {
    position: absolute;
    right: 15px;
    /* Adjust to align with the cart icon */
    top: 60px;
    /* Adjust to position below the header */
    width: 300px;
    /* Adjust width as needed */
    z-index: 1000;
}

.cart-fade-enter-active,
.cart-fade-leave-active {
    transition: opacity 0.5s;
}

.cart-fade-enter,
.cart-fade-leave-to {
    opacity: 0;
}

.product__item__pic {
    border: 1px solid rgb(232, 225, 225);
}

.product__item__text h5 .prix-old {
    text-decoration: line-through;
    margin-right: 10px;
    opacity: 45%;
    color: #999;
    /* Optional: to differentiate the old price color */
}

.scrollable-content {
    max-height: 200px;
    /* Adjust the height as needed */
    overflow-y: auto;
}

/* Webkit-based browsers */
.scrollable-content::-webkit-scrollbar {
    width: 10px !important;
    /* Width of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    /* Background of the scrollbar track */
    border-radius: 10px !important;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: #888 !important;
    /* Color of the scrollbar handle */
    border-radius: 10px !important;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background: red !important;
    /* Color of the scrollbar handle on hover */
}

/* Firefox */
.scrollable-content {
    scrollbar-width: thin !important;
    /* Width of the scrollbar */
    scrollbar-color: #888 #f1f1f1 !important;
    /* Color of the scrollbar handle and track */
}
</style>
